import React from 'react';
import './Contacts.css'
import vector from '../../image/Vector.png'

function Contacts() {
    return (
        <div className='contacts' id="contacts">
            <h2 className='contacts__title'>CONTACT US</h2>

            <div className='contacts__grid'>
                <div className='contacts__element'>
                    <img className='contact__img' src={vector} />
                    <h3>Sales Department</h3>
                    <p className='contacts__subtitle'>info@emu-max.com</p>
                </div>

                <div className='contacts__element'>
                    <img className='contact__img' src={vector} />
                    <h3>Support</h3>
                    <p className='contacts__subtitle'>support@emu-max.com</p>
                </div>
            </div>
        </div>
    );
  }
  
  export default Contacts;