import React from 'react';
import './Emulators.css'

import A420 from '../../image/about/A420.png';
import FULL from '../../image/about/FULL.png';
import ONLYKM from '../../image/about/ONLY-KM.png';
import MULTI4 from '../../image/about/MULTI-4.png';
import MULTI6 from '../../image/about/MULTI-6.png';
import MULTI7 from '../../image/about/MULTI-7.png';
import FISC5 from '../../image/about/FI-SC5.png';
import E1620 from '../../image/about/E1620.png';


import IP44 from '../../image/about/ip44.png'
import IP67 from '../../image/about/ip67.png';

import provod4 from '../../image/about/provod4.png';
import provod6 from '../../image/about/provod6.png';
import provod7 from '../../image/about/provod7.png';
import provod10 from '../../image/about/provod10.png';

import garantia from '../../image/about/garantia.png'
import emukeynot from '../../image/about/emukeynot.png'
import emukey from '../../image/about/emukey.png';

import photo1 from '../../image/A420_01_1500x1500.jpg';
import photo3 from '../../image/FULL_01_1500x1500.jpg';
import photo4 from '../../image/ONLYKM_01_1500x1500.jpg';
import photo5 from '../../image/MULTI4_01_1500x1500.jpg';
import photo6 from '../../image/MULTI6_01_1500x1500.jpg';
import photo7 from '../../image/MULTI7_01_1500x1500.jpg';
import photo8 from '../../image/FI_SC5_01_1500x1500.jpg';
import photo9 from '../../image/E1620_1500x1500.jpg';
import photo10 from '../../image/DONGLE_01_1500x1500.jpg';


function Emulators(props) {

    return (
      <section className="emulators" id="emulators">
        <h2 className='emulatrs__title'>PRODUCTS</h2>
        <div className='emulators__grid'>
          <div className='emuiator__grupo'>
          <h2 className='emulator__title'>EMULATOR ADBLUE EMU-MAX A420</h2>
          <div className="emulator__about">
                  <img className="emulator__about_element" src={IP44} />
                  <img className="emulator__about_element" src={provod4} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukeynot} />
          </div>
            <div className='emulator__element'>
              <img src={photo1} className='emulator__img' />
              <img src={A420} className='emulator__imgabout' />
            </div>
            </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>EMULATOR ADBLUE EMU-MAX FULL</h2>
            <div className="emulator__about">
                  <img className="emulator__about_element" src={IP44} />
                  <img className="emulator__about_element" src={provod10} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukey} />
            </div>
            <div className='emulator__element'>
              <img src={photo3} className='emulator__img' />
              <img src={FULL} className='emulator__imgabout' />
            </div>
          </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>EMULATOR EMU-MAX ONLY-KM</h2>
            <div className="emulator__about">
                  <img className="emulator__about_element" src={IP44} />
                  <img className="emulator__about_element" src={provod10} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukeynot} />
            </div>
            <div className='emulator__element'>
              <img src={photo4} className='emulator__img' />
              <img src={ONLYKM} className='emulator__imgabout' />
            </div>
          </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>EMULATOR ADBLUE EMU-MAX MULTI-4</h2>
            <div className="emulator__about">
                  <img className="emulator__about_element" src={IP67} />
                  <img className="emulator__about_element" src={provod4} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukey} />
            </div>
            <div className='emulator__element'>
              <img src={photo5} className='emulator__img' />
              <img src={MULTI4} className='emulator__imgabout' />
            </div>
          </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>EMULATOR ADBLUE EMU-MAX MULTI-6</h2>
            <div className="emulator__about">
                  <img className="emulator__about_element" src={IP67} />
                  <img className="emulator__about_element" src={provod6} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukey} />
            </div>
            <div className='emulator__element'>
              <img src={photo6} className='emulator__img' />
              <img src={MULTI6} className='emulator__imgabout' />
            </div>
          </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>EMULATOR ADBLUE EMU-MAX MULTI-7</h2>
            <div className="emulator__about">
                  <img className="emulator__about_element" src={IP67} />
                  <img className="emulator__about_element" src={provod7} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukey} />
            </div>
            <div className='emulator__element'>
              <img src={photo7} className='emulator__img' />
              <img src={MULTI7} className='emulator__imgabout' />
            </div>
          </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>EMULATOR ADBLUE EMU-MAX FI-SC5</h2>
            <div className="emulator__about ">
                  <img className="emulator__about_element" src={IP67} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukeynot} />
            </div>
            <div className='emulator__element'>
              <img src={photo8} className='emulator__img' />
              <img src={FISC5} className='emulator__imgabout' />
            </div>
          </div>

          <div className='emuiator__grupo'>
            <h2 className='emulator__title'>ERASER DTC EMU-MAX E1620</h2>
            <div className="emulator__about">
                  <img className="emulator__about_element" src={IP44} />
                  <img className="emulator__about_element" src={garantia} />
                  <img className="emulator__about_element" src={emukeynot} />
                 
            </div>
            <div className='emulator__element'>
              <img src={photo9} className='emulator__img' />
              <img src={E1620} className='emulator__imgabout' />
            </div>
          </div>
        </div>
      </section>
    );
  }
  
  export default Emulators;