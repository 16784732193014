import React from "react";
import "./Promo.css";

function Promo() {
    return(
        <>
        <section className="promo">
            <div className="promo__text">
                <h1 className="promo__title">
                    Emulator Emu-Max
                </h1>
                <p className="promo__subtitle">
                A&nbsp;universal electronic device that fully replicates 
                the logic of&nbsp;a&nbsp;functioning vehicle SCR system, 
                depending on&nbsp;the make and model of&nbsp;the car.
                </p>
            </div>
            <button className="promo__button">
                <a href="#emulators" className="promo__button_link">Products</a>
            </button>
        </section>
        </>
    )
}

export default Promo;