import React,  { useState } from 'react';
/* import { Routes } from 'react-router-dom'; */
import { useEffect } from 'react';
import './App.css'
import Header from '../Header/Header';
import Promo from '../Promo/Promo';
import Benefits from '../Benefits/Benefits';
//import Video from '../Video/Video';
import Emulators from '../Emulatots/Emulators';
import Buy from '../Buy/Buy';
import Contacts from '../Contacts/Contacts';
import Footer from '../Footer/Footer';

function App() {

  const [selectedCard, setSelectCard] = useState({ open: false, dataCard: {} });

  useEffect(() => {
    function closePopupEsp(evt) {
      if (evt.key === 'Escape') {
        closeAllPopups()
      }
    }
    window.addEventListener('keydown', closePopupEsp)
    return () => {
      window.removeEventListener('keydown', closePopupEsp)
    }
  }, [])

  const closeAllPopups = () => {
    setSelectCard({ open: false, dataCard: {} });
  }

    return (
      <div className="page">
        <Header />
        <Promo />
        <Benefits />
        <Emulators
         />
         <Buy />
        <Contacts />  
        <Footer />
      </div>
    );
  }
  
  export default App;