import React from "react";
import { useState } from "react";
//import { Link } from "react-router-dom";
import './Navigation.css';

function Navigation() {
    const [showMenu, setShowMenu] = useState(false);

  const handleToggleMenu = () => setShowMenu(!showMenu);

    return (
        <section className="header__navigation">
            <button className="navigation__menu" type="button" onClick={handleToggleMenu}></button>
            <div className={`navigation__container ${showMenu ? 'navigation__container_visible' : ''}`}>

                <div className="navigation__sidebar">
                    <div className="navigation__container-list">
                        <button className="navigation__close" type="button" onClick={handleToggleMenu}></button>
                        <ul className="navigation__list">
                            <li className="navigation__list-item">
                                <a href="#emulators" className="navigation__link">Products</a>
                            </li>
                            {/* <li className="navigation__list-item">
                                <a href="#buy" className="navigation__link" >Где купить</a>
                            </li> */}
                            <li className="navigation__list-item">
                                <a href="#contacts" className="navigation__link">Contact us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Navigation;